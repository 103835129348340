export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [9],
		"/about": [11],
		"/admin": [12,[2]],
		"/admin/banners-management": [13,[2]],
		"/admin/banners-management/add": [15,[2]],
		"/admin/banners-management/[id]": [14,[2]],
		"/admin/categories-management": [16,[2]],
		"/admin/category": [17,[2,3]],
		"/admin/category/[id]": [18,[2,3]],
		"/admin/certificates-management": [19,[2]],
		"/admin/certificates-management/add": [21,[2]],
		"/admin/certificates-management/[id]": [20,[2]],
		"/admin/dashboard": [22,[2]],
		"/admin/footer-management": [23,[2]],
		"/admin/footer-management/add": [25,[2]],
		"/admin/footer-management/[id]": [24,[2]],
		"/admin/knowledge-management": [26,[2]],
		"/admin/knowledge-management/add": [28,[2]],
		"/admin/knowledge-management/[id]": [27,[2]],
		"/admin/news-management": [29,[2]],
		"/admin/news-management/add": [31,[2]],
		"/admin/news-management/[id]": [30,[2]],
		"/admin/orders-management": [32,[2,4]],
		"/admin/orders-management/[id]": [33,[2,4]],
		"/admin/outsource-management": [34,[2]],
		"/admin/outsource-management/add": [36,[2]],
		"/admin/outsource-management/[id]": [35,[2]],
		"/admin/partners-management": [37,[2]],
		"/admin/partners-management/add": [39,[2]],
		"/admin/partners-management/[id]": [38,[2]],
		"/admin/products-management": [42,[2]],
		"/admin/product": [40,[2,5]],
		"/admin/product/[id]": [41,[2,5]],
		"/admin/projects-management": [43,[2]],
		"/admin/projects-management/add": [45,[2]],
		"/admin/projects-management/[id]": [44,[2]],
		"/admin/sale-consultant-management": [46,[2]],
		"/admin/sale-consultant-management/add": [48,[2]],
		"/admin/sale-consultant-management/[id]": [47,[2]],
		"/admin/specifications-management": [51,[2]],
		"/admin/specification": [49,[2,6]],
		"/admin/specification/[id]": [50,[2,6]],
		"/admin/sub-categories-management": [52,[2]],
		"/admin/sub-category": [53,[2,7]],
		"/admin/sub-category/[id]": [54,[2,7]],
		"/admin/user-requests-management": [55,[2]],
		"/admin/user-requests-management/[id]": [56,[2]],
		"/admin/users-management": [57,[2]],
		"/cart": [58],
		"/certification": [59],
		"/chi-tiet-san-pham": [60],
		"/chi-tiet-san-pham/[id]": [61],
		"/contact": [62],
		"/detail": [63],
		"/login": [64],
		"/news": [65],
		"/news/[id]": [66],
		"/out-sourcing": [67],
		"/projects": [68],
		"/projects/[id]": [69],
		"/register": [70],
		"/san-pham": [71],
		"/share-knowledge": [72],
		"/share-knowledge/[id]": [73],
		"/[...slugs]": [10]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';